// @ts-ignore MarketStore is not typed
import MarketsStore from '../index'
import type { ActualLoader, MarketStoreInstance } from './types'
import { CormacheHomePageLoader, CormacheLoader, SbLoader } from './loaders'
import type { Socket } from '@st/socket'

export interface MarketStoreFactoryOptions {
  sportsbookConfig: {
    api: string
    key: string
    user: string
  }
  cormacheConfig?: {
    api?: string
  }
  locale: string
  allowedLocales: string[]
  timeoutAfterSleep: number
  io: Socket
  actualMarketsIds: number[]
  platform: 'mobile' | 'desktop'
  isHomePage?: boolean
  adminMode?: boolean
  // флаг для оптимизации загрузки актуала
  // если true, то изначально будет пустой массив маркетов (для экономии памяти)
  optimizedMarkets?: boolean
}

export class MarketStoreFactory {
  public marketStore: MarketStoreInstance
  public usedLoaderName: string | undefined
  options: MarketStoreFactoryOptions

  constructor(options: MarketStoreFactoryOptions) {
    this.options = options
    this.marketStore = this.#createMarketStoreInstance()
  }

  public async getActual(): Promise<void> {
    const loaders = this.getLoaders()
    for (const loader of loaders) {
      try {
        console.info(`loading actual with ${loader.name}`)
        await loader.getActual()
        this.usedLoaderName = loader.name
        return
      } catch (error) {
        console.error(error)
      }
    }
  }

  #createMarketStoreInstance(): MarketStoreInstance {
    const {
      sportsbookConfig,
      locale,
      timeoutAfterSleep,
      io,
      actualMarketsIds,
      allowedLocales,
      adminMode,
      optimizedMarkets,
    } = this.options

    const lang = allowedLocales.includes(locale) ? locale : allowedLocales[0]
    const fallbackLang =
      allowedLocales.find((allowedLocale) => allowedLocale !== lang) ?? 'en'

    return new MarketsStore(
      {
        sportbookApi: sportsbookConfig.api,
        sportbookKey: sportsbookConfig.key,
        sportbookUser: sportsbookConfig.user,
        actualMarketsIds,
        lang,
        fallbackLang,
        io,
        timeoutAfterSleep,
        disableSelfDownload: true,
        optimizedMarkets,
      },
      false,
      adminMode,
    )
  }

  /**
   * Определяем какие методы загрузки актуала использовать
   * Возвращает массив загрузчиков. Сначала используется первый, если он выдает ошибку - второй и т.д.
   */
  getLoaders(): ActualLoader[] {
    const { cormacheConfig, platform, isHomePage } = this.options

    if (cormacheConfig?.api && isHomePage) {
      return [
        new CormacheHomePageLoader(this.marketStore, {
          cacheUrl: cormacheConfig.api,
          platform,
        }),
        new SbLoader(this.marketStore),
      ]
    }

    if (cormacheConfig?.api && !isHomePage) {
      return [
        new CormacheLoader(this.marketStore, {
          cacheUrl: cormacheConfig.api,
          platform,
        }),
        new SbLoader(this.marketStore),
      ]
    }

    return [new SbLoader(this.marketStore)]
  }
}
